<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader tag="div">
          <CIcon name="cil-grid"/>
          Cập nhật nhóm quyền
        </CCardHeader>
        <CCardBody style="min-height: 300px" tag="div">
          <CForm>
            <CRow>
              <CCol lg="6">
                <CInput label="Tên nhóm" horizontal :value.sync="detailItem.name"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CSelect label="Loại đơn vị" horizontal :value.sync="detailItem.loaiDonVi" :options="optionsLoaiDonVi"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <div role="group" class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label">Chức năng</CCol>
                  <CCol sm="9">
                    <multiselect v-model="selectedPermissions" :options="optionsPermissions" :multiple="true"
                                 group-values="items"
                                 group-label="groupName" :group-select="true" track-by="value"
                                 label="label" placeholder="Chọn chức năng" selectLabel="Nhấn để chọn"
                                 selectGroupLabel="Nhấn để chọn theo nhóm" selectedLabel="Đã chọn"
                                 deselectLabel="Nhấn để xóa" deselectGroupLabel="Nhấn để xóa theo nhóm"
                                 @input="updatePermissions">
                      <span slot="noResult">Không tìm thấy.</span>
                    </multiselect>
                  </CCol>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CTextarea label="Mô tả" horizontal :value.sync="detailItem.description"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <div role="group" class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                  <CCol sm="9" class="form-inline">
                    <CInputCheckbox label="Hoạt động" :checked.sync="detailItem.status"/>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
          <CElementCover v-if="isLoading"/>
        </CCardBody>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
          <CButton color="primary" @click="saveItem" :disabled="isSaving">Lưu lại</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  GET_APPLICATION_ROLE,
  UPDATE_APPLICATION_ROLE
} from '@/store/modules/application-role/actionTypes'
import { enums } from '@/shared/enums'
import { applicationRoleService } from '@/services/application-role.service'

export default {
  name: 'ApplicationRoleUpdate',
  data () {
    return {
      selectedPermissions: [],
      isLoading: false,
      optionsPermissions: [],
      optionsLoaiDonVi: enums.loaiDonVi
    }
  },
  computed: {
    ...mapGetters('applicationRole', {
      isSaving: 'isSaving',
      responseResult: 'responseResult',
      detailItem: 'detailItem'
    })
  },
  methods: {
    ...mapActions('applicationRole', {
      getItem: GET_APPLICATION_ROLE,
      updateItem: UPDATE_APPLICATION_ROLE
    }),
    async saveItem () {
      await this.updateItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/nhom-quyen' })
      }
    },
    updatePermissions (val) {
      if (val && val.length > 0) {
        const arrPermissions = val.map(x => x.value)
        this.detailItem.permissions = arrPermissions.join(',')
      }
    },
    cancel () {
      this.$router.push({ path: '/nhom-quyen' })
    }
  },
  watch: {
    'detailItem.loaiDonVi': function (newVal, oldVal) {
      if (newVal && oldVal && newVal !== oldVal) {
        this.selectedPermissions = []
        applicationRoleService.getPermissions(newVal).then((data) => {
          this.optionsPermissions = data
        })
      }
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getItem(this.$route.params.id)
    this.optionsPermissions = await applicationRoleService.getPermissions(this.detailItem.loaiDonVi)
    if (this.detailItem && this.detailItem.permissions) {
      const permissions = this.detailItem.permissions.split(',')
      permissions.forEach(per => {
        this.optionsPermissions.forEach(p => {
          const item = p.items.find(x => x.value === per)
          if (item) {
            this.selectedPermissions.push(item)
          }
        })
      })
    }

    this.isLoading = false
  }
}
</script>
